/**
 * easier to manage all gsap dependencies
 * !IMPORTANT - make sure to remove every plugins that you don't need in production, i'm looking at you `GSDevTools`
 */
import {
  Back, Bounce, Circ, Cubic, Elastic, Expo, gsap, Linear, Power0, Power1, Power2, Power3, Power4,
  Quad, Quart, Quint, Sine, Strong
} from 'gsap';
import { CSSPlugin } from 'gsap/CSSPlugin';
import { CSSRulePlugin } from 'gsap/CSSRulePlugin';
import { CustomEase } from 'gsap/CustomEase';
// import { GSDevTools } from 'gsap/GSDevTools';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import { MotionPathHelper } from 'gsap/MotionPathHelper';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from 'gsap/SplitText';
// arke, this is not ready
// import { ScrollSmoother } from 'gsap/ScrollSmoother';

gsap.registerPlugin(CSSPlugin, CSSRulePlugin)
gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(SplitText)
// gsap.registerPlugin(GSDevTools)
gsap.registerPlugin(CustomEase);
gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(DrawSVGPlugin);

gsap.registerPlugin(MotionPathPlugin);
gsap.registerPlugin(MotionPathHelper);

// gsap.registerPlugin(ScrollSmoother)

// ¯\_(ツ)_/¯ avoid weird bug on build - `Linear not found`
// @ts-ignore
window.eases = {
  Linear, Back, Bounce, Elastic,
  Circ, Cubic, Quad, Quart, Quint, Sine, Strong, Expo,
  Power0, Power1, Power2, Power3, Power4
};

export {
  gsap,
  SplitText,
  ScrollTrigger,
  // GSDevTools,
  CustomEase,
  MotionPathPlugin,
  MotionPathHelper,
  // ScrollSmoother,
  Linear, Back, Bounce, Elastic,
  Circ, Cubic, Quad, Quart, Quint, Sine, Strong, Expo,
  Power0, Power1, Power2, Power3, Power4
};

